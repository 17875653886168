$ruler: 16px;
$color-bg: #ebecf0;
$color-red: #ae1100;
$color-shadow: #babecc;
$color-white: #fff;

div,
p {
  color: darken($color-shadow, 15);
  text-shadow: 1px 1px 1px $color-white;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: -0.2px;
  font-size: $ruler;
  background-color: $color-bg;
  height: 100%;
}

.main-container {
  padding: 2rem;
  margin: 5rem;
  border-radius: 2.5rem;
  background-color: $color-bg;
  // box-shadow: 10px 10px 15px $color-shadow, -10px -10px 15px $color-white;
}

.morph {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.75rem;
  border-radius: 3rem;
  background-color: $color-bg;
  box-shadow: 5px 5px 10px $color-shadow, -5px -5px 10px $color-white;
}

body a {
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: darken($color-shadow, 20);
  text-shadow: 1px 1px 1px $color-white;
  font-family: 'Libre Baskerville', serif;
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

.divider {
  margin: 75px 0;
  box-shadow: 5px 5px 10px darken(#cbced1, 2), -5px -5px 10px darken(#ffffff, 2);
  height: 7.5px;
}

.menu {
  &::before {
    display: none !important;
  }
}

.top-menu {
  margin: 9em 0 0 0;
  // border-top: 3px solid #000;
  position: relative;
  // border-bottom: 3px solid #000;
  height: 100px;
}

.segment {
  padding: $ruler * 2 0;
  display: flex;
  justify-content: center;
  button > svg {
    font-size: 1.25em;
  }
}
.morph-button,
input {
  border: 0;
  outline: 0;
  font-size: $ruler;
  border-radius: $ruler;
  padding: $ruler;
  background-color: darken($color-bg, 1.5);
  text-shadow: 1px 1px 0 $color-white;
}

label {
  display: block;
  margin-bottom: $ruler * 1.5;
  width: 100%;
}

.timeline-paragraph {
  font-size: 15px !important;
}

.vertical-timeline-element-date {
  font-size: 14px !important;
}

.vertical-timeline::before {
  background: lighten($color-shadow, 10);
}

form {
  padding: $ruler;
  max-width: $ruler * 40;
  margin: 0 auto;

  input,
  textarea {
    cursor: text;
    margin-right: $ruler/2;
    box-shadow: inset 2px 2px 5px $color-shadow,
      inset -5px -5px 10px $color-white;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    appearance: none;
    -webkit-appearance: none;

    &:focus {
      box-shadow: inset 1px 1px 2px $color-shadow,
        inset -1px -1px 2px $color-white;
    }
    &::placeholder {
      color: darken($color-shadow, 10);
    }
  }

  textarea {
    border: 0;
    outline: 0;
    font-size: $ruler;
    border-radius: $ruler;
    padding: $ruler;
    background-color: darken($color-bg, 1.5);
    text-shadow: 1px 1px 0 $color-white;
  }
}

.morph-button {
  color: #61677c;
  font-weight: bold;
  box-shadow: -5px -5px 20px $color-white, 5px 5px 20px $color-shadow;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
  }

  &:active {
    box-shadow: inset 1px 1px 2px $color-shadow,
      inset -1px -1px 2px $color-white;
  }

  .icon {
    margin-right: $ruler/2;
  }

  &.unit {
    border-radius: $ruler/2;
    line-height: 0;
    width: $ruler * 4;
    height: $ruler * 4;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 $ruler/2;
    font-size: $ruler * 1.2;

    .icon {
      margin-right: 0;
    }
  }
  &.wide {
    min-width: 200px;
    max-width: 300px;
    height: 50px;
  }

  &.red {
    display: block;
    width: 100%;
    color: $color-red;
  }
}

.react-images__view-image {
  height: 700px !important;
  width: 400px !important;
  // border-radius: 10px !important;
}

.input-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    margin: 0;
    flex: 1;
  }
}

.nav {
  list-style: none;
  padding: 1.5em 0;
  // border-top: 1px solid #000;
  margin: 3px 0;
  // border-bottom: 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul.nav li {
  display: inline-block;
}

.nav li a {
  display: block;
  padding: 0px;
  font-size: 1.5em;
  font-weight: 400;
}

li.logo {
  background: none;
  border: none;

  a {
    font-size: 5em;
    color: #17aed2;
  }
}

div#banner-top {
  text-align: center;
  padding-top: 6em;

  h1 {
    font-size: 4.8em;
  }

  h2 {
    font-size: 2em;
    padding: 0.5em 0;
    width: 77%;
    margin: 0 auto;
  }
}

.work {
  text-align: center;
  margin: 5em 0 0;

  p {
    font-size: 1.4em;
    margin-bottom: 4em;
  }

  h3 {
    font-size: 3.6em;
    padding: 0.5em 0;
    width: 51%;
    margin: 3% auto 0;
  }
}

.project-top h3 {
  float: left;
  color: #324b73;
  font-weight: 900;
  font-size: 2em;
  margin-top: 2em;
}

.span_1_of_4 {
  width: 22.8%;
}

.col_1_of_4 {
  display: block;
  float: left;
  margin: 1% 0 1% 2.6%;
  background: #fff;
}

#portfoliolist .portfolio {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  width: 24%;
  display: none;
  float: left;
  overflow: hidden;
  margin: 0 1% 1% 0;
  background: none;
}

.portfolio.icon.mix_all {
  padding: 0;
}

div#portfoliolist {
  padding: 0.5% 0px;
}

.project h5 {
  color: #334d72;
  text-align: center;
  font-size: 2.6em;
  font-weight: 600;
  padding: 1em 0 0em;
}

.portfolio {
  img {
    max-width: 100%;

    transition: all 300ms !important;
    -webkit-transition: all 300ms !important;
    -moz-transition: all 300ms !important;
  }

  .label {
    position: absolute;
    width: 100%;
    height: 40px;
    bottom: -40px;
  }

  .label-bg {
    background: #22b4b8;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .label-text {
    color: #fff;
    position: relative;
    z-index: 500;
    padding: 5px 8px;
  }

  .text-category {
    display: block;
    font-size: 9px;
    font-size: 12px;
    text-transform: uppercase;
  }
}

.container:after {
  content: '\0020';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.project {
  margin-bottom: 8em;
}

.about {
  text-align: center;
  margin: 4em 0;
}

.name-heading {
  display: flex;
  justify-content: center;
  color: darken($color-shadow, 20);
}

#workgrid {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 20px;
  .portfolio {
    &-overlay-wrapper {
      background: darken($color-bg, 1.5);
      height: 100%;
      width: 100%;
      opacity: 0;
      padding: 0;
      transition: opacity 0.5s;
      overflow: hidden;
      position: relative !important;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      font-size: 14px;
    }
    &-item {
      position: relative;
      &__in-progress {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 20px;
        }
      }
      &__display {
        position: absolute;
        width: 80%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        &-name {
          font-size: 18px;
        }
        &-icons {
          &-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
          }
          &-flex {
            display: flex;
            flex-wrap: wrap;
            img {
              margin: 0 10px;
            }
          }
          width: 100%;
          align-items: center;
          justify-content: center;
          font-size: 25px;
          img {
            justify-self: center;
            align-self: center;
          }
        }
      }
      &__description {
        cursor: default;
      }
      &__buttons {
        cursor: pointer;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      width: 275px;
      height: 275px;
      &:hover {
        .portfolio-overlay-wrapper {
          opacity: 1;
          transition: opacity 0.5s;
        }
      }
    }
  }
}

.about {
  h3 {
    font-size: 3.6em;
    padding: 0.5em 0;
    width: 55%;
    margin: 3% auto 0;
  }

  p {
    font-size: 1.3em;
  }
}

.about-right {
  text-align: left;

  p {
    padding: 10px 0;
    line-height: 1.8em;
    font-size: 1.3em;
  }
}

.about-info {
  margin: 6em 0;
}

.contact {
  text-align: center;

  h3 {
    font-size: 3.6em;
    padding: 0.5em 0;
    width: 62%;
    margin: 2% auto 0;
  }

  p {
    margin-bottom: 2.5em;
    font-size: 1.3em;
  }
}

ul.form li {
  list-style-type: none;
  border: 4px solid #000;
  width: 50%;
  margin: 2% auto 0;
}

input.text {
  border: 1px solid #000;
  outline: none;
  width: 98%;
  margin: 4px;
  padding: 1.2em;
}

textarea {
  outline: none;
  border: 1px solid #000;
  padding: 1em;
  width: 98%;
  height: 200px;
  margin: 4px;
  resize: none;
}

.sub-button {
  border: 4px solid #000;
  width: 20%;
  margin: 2% auto;
}

input[type='submit'] {
  border: 1px solid #000;
  outline: none;
  background: none;
  margin: 4px;
  padding: 1em 4em;
  font-size: 1.2em;
  width: 96%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #000;
    color: #fff;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
  }
}

.contact-form {
  margin-bottom: 2.5em;
}

.copyrights {
  // border-top: 1px solid #000;
  padding: 2em 0 2em;

  p {
    margin-bottom: 0;
    font-size: 1.2em;
  }
}

.vertical-timeline-element-date {
  width: 50% !important;
}

.portfolio-item {
  background-color: darken($color-bg, 1.5) !important;
}

@media (max-width: 1400px) {
  .vertical-timeline-element-date {
    width: 50% !important;
  }
  .container {
    width: 100% !important;
  }
  #workgrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    .portfolio-item {
      background-color: darken($color-bg, 1.5) !important;
      margin: 20px;
      width: 275px;
      height: 275px;
    }
  }
}

@media (max-width: 1171px) {
  .vertical-timeline-element-date {
    width: 100% !important;
    justify-self: center !important;
    text-align: center !important;
  }
}
@media (max-width: 1024px) {
  .vertical-timeline-element-date {
    width: 100% !important;
    justify-self: center !important;
    text-align: center !important;
  }
  .main-container {
    padding: 1rem;
    margin: 2rem;
    border-radius: 2.5rem;
    background-color: $color-bg;
    box-shadow: 10px 10px 15px $color-shadow, -10px -10px 15px $color-white;
  }

  .container {
    width: 100% !important;
  }

  #workgrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    .portfolio-item {
      justify-self: center;
      height: 275px;
      width: 275px;
    }
  }

  .label-2 {
    width: 941px;
  }

  .top-menu {
    margin: 7em 0 0 0;
  }

  .nav li a {
    margin: 0 20px;
  }

  div#banner-top {
    h1 {
      font-size: 4em;
    }

    padding-top: 4em;

    h2 {
      font-size: 2em;
      width: 91%;
      margin: 2% auto;
    }
  }

  .work {
    text-align: center;
    margin: 4em 0 0;

    h3 {
      font-size: 3em;
      width: 66%;
    }
  }

  .about {
    margin: 0 0;

    h3 {
      font-size: 3em;
      width: 66%;
    }
  }

  .contact {
    h3 {
      font-size: 3em;
      width: 73%;
    }

    p {
      margin-bottom: 4em;
    }
  }

  .copyrights {
    padding: 2em 0 0em;
  }
}

@media (max-width: 768px) {
  .main-container {
    padding: 1rem;
    margin: 2rem;
    border-radius: 2.5rem;
    background-color: $color-bg;
    box-shadow: 10px 10px 15px $color-shadow, -10px -10px 15px $color-white;
  }

  .vertical-timeline-element-date {
    justify-self: center !important;
  }

  #workgrid {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    .portfolio-item {
      justify-self: center;
      width: 250px;
      height: 250px;
    }
  }

  .container {
    width: 100% !important;
  }

  div#banner-top {
    h1 {
      font-size: 2.7em;
      width: 100%;
      margin: 0 auto;
    }

    h2 {
      font-size: 1.3em;
      width: 100%;
      margin: 1% auto;
    }

    padding-top: 1.5em;
  }

  .work {
    margin: 4em 0 0;
  }

  .label-2 {
    width: 718px;
    background: #000;
  }

  .nav li a {
    margin: 0 10px;
  }

  .work {
    h3 {
      font-size: 2.6em;
      width: 82%;
    }

    p {
      font-size: 1.4em;
      margin-bottom: 3em;
    }
  }

  .project {
    margin-bottom: 5em;
  }

  .about h3 {
    font-size: 2.6em;
    width: 78%;
  }

  .top-menu {
    margin: 8% auto 3%;
    width: 95%;
  }

  .about-left {
    width: 42%;
    float: left;
  }

  .about-right {
    width: 100%;
    font-size: 0.875em;
    padding: 0;
  }

  .about-icons {
    text-align: center;
  }

  .about-info {
    margin: 5em 0 0;
  }

  .about-icons {
    margin: 5em 0 0 0;
  }

  div#portfoliolist {
    padding: 0 13px 0 20px;
  }

  .contact h3 {
    font-size: 2.6em;
    width: 88%;
    margin: 4% auto 0;
  }

  .b-animate {
    top: 21%;
  }

  ul.form li {
    width: 68%;
  }

  .sub-button {
    width: 30%;
  }

  .label-3,
  .label-4,
  .label-5,
  .label-6,
  .label-7,
  .label-8 {
    width: 96%;
    margin: 0 auto;
  }
}

@media (max-width: 640px) {
  .main-container {
    padding: 1rem;
    margin: 2rem;
    border-radius: 2.5rem;
    background-color: $color-bg;
    box-shadow: 10px 10px 15px $color-shadow, -10px -10px 15px $color-white;
  }

  .container {
    width: 100% !important;
  }

  .vertical-timeline-element-title {
    font-size: 2rem;
  }

  .divider {
    margin: 35px 0;
    box-shadow: 2px 2px 5px #cbced1, -2px -2px 5px #ffffff;
    height: 7.5px;
  }

  #workgrid {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    .portfolio-item {
      justify-self: center;
      width: 275px;
      height: 275px;
    }
  }

  .nav {
    li a {
      margin: 0 16px;
      font-size: 12px;
    }

    padding: 1em 0;
  }

  .sub-button {
    width: 35%;
  }

  .logo a img {
    width: 100%;
  }

  div#banner-top {
    h1 {
      font-size: 2.2em;
    }

    h2 {
      font-size: 1em;
      width: 100%;
    }
  }

  .work h3 {
    font-size: 2.3em;
    width: 94%;
    margin: 2% auto 0;
  }

  .label-2 {
    width: 594px;
  }

  .about h3 {
    font-size: 2.3em;
    width: 94%;
  }

  .about-icons {
    margin: 2em 0 0 0;
  }

  .contact {
    margin: 3em 0;

    h3 {
      font-size: 2.3em;
      width: 100%;
      margin: 2% auto 0;
    }
  }

  ul.form li {
    width: 80%;
  }

  .about-right p {
    padding: 0px 0;
    font-size: 1.1em;
  }

  .top-menu {
    margin: 8% auto 5%;
    width: 96%;
    height: 72px;
  }
}

@media (max-width: 480px) {
  .vertical-timeline-element-title {
    font-size: 1.75rem;
  }

  .main-container {
    padding: 1rem;
    margin: 2rem;
    border-radius: 2.5rem;
    background-color: $color-bg;
    box-shadow: 10px 10px 15px $color-shadow, -10px -10px 15px $color-white;
  }

  .divider {
    margin: 40px 0;
    box-shadow: 2px 2px 5px #cbced1, -2px -2px 5px #ffffff;
    height: 3.5px;
  }

  .container {
    width: 100% !important;
  }

  #workgrid {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    .portfolio-item {
      justify-self: center;
      width: 275px;
      height: 275px;
    }
  }

  span.menu:before {
    cursor: pointer;
    height: 32px;
  }

  .top-menu {
    float: right;
    margin: 0;
    background: none;
    border: none;

    ul {
      display: none;

      li {
        font-size: 12px;
        display: inline-block;
        width: 100%;
        padding: 10px 0;

        &:hover {
          background: #000;
          color: #fff;
        }

        a {
          padding: 3px;
          font-size: 13px;
          width: 100%;
          font-weight: 600;
          display: block;
          margin: 0;
          color: #fff;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }

  span.menu {
    display: block;
    float: right;
    margin: 54px 16px 0 0;
  }

  .top-menu ul {
    margin: 0;
    z-index: 999;
    position: absolute;
    width: 96%;
    text-align: center;
    top: 82.8%;
    left: -6%;
    background: rgba(0, 0, 0, 0.81);
    width: 440px;
  }

  .nav {
    padding: 0;
  }

  .label-2 {
    width: 0;
    background: none;
  }

  div#banner-top {
    h1 {
      font-size: 2.2em;
    }

    padding-top: 3em;

    h2 {
      font-size: 1.25em;
      margin-left: 0;
    }
  }

  .work {
    margin: 2em 0 0;

    h3 span {
      &.right-icon,
      &.left-icon {
        display: none;
      }
    }
  }

  .about h3 span {
    &.left-icon,
    &.right-icon {
      display: none;
    }
  }

  .contact h3 span {
    &.left-icon,
    &.right-icon {
      display: none;
    }
  }

  .work {
    p {
      font-size: 1.1em;
      margin-bottom: 2em;
    }

    h3 {
      margin: 0% auto 0;
    }
  }

  .project {
    margin-bottom: 2em;
  }

  #portfoliolist .portfolio {
    width: 49%;
  }

  .about p {
    font-size: 1.1em;
  }

  .about-info {
    margin: 2em 0 0;
  }

  .contact {
    margin: 2em 0;

    p {
      margin-bottom: 2em;
      font-size: 1.1em;
    }
  }

  ul.form li {
    width: 90%;
  }

  .sub-button {
    width: 50%;
  }

  .contact p {
    margin-bottom: 4em;
    font-size: 0.9em;
  }

  .contact-form {
    margin-bottom: 1em;
  }

  textarea {
    margin: 4px 0px -3px 0;
  }
}

@media (max-width: 320px) {
  .vertical-timeline-element-title {
    font-size: 1.5rem;
  }

  #workgrid {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    .portfolio-item {
      justify-self: center;
      width: 235px;
      height: 235px;
    }
  }

  .unit {
    justify-content: center;
    align-items: center;
    width: 50px !important;
    height: 50px !important;
  }

  .main-container {
    margin: 2rem;
  }

  .divider {
    margin: 25px 0;
    box-shadow: 2px 2px 5px #cbced1, -2px -2px 5px #ffffff;
    height: 3.5px;
  }

  .header-icons {
    margin: 1em 0 0;
  }

  .work {
    margin: 1.5em 0 0;

    h3 {
      font-size: 1.4em;
      width: 100%;
    }

    p {
      font-size: 0.81em;
      margin-bottom: 1em;
    }
  }

  span.menu {
    margin: 39px 8px 0 0;
  }

  .top-menu ul {
    top: 95.8%;
    width: 285px;
  }

  div#banner-top {
    padding-top: 2.5em;
  }

  .about {
    h3 {
      font-size: 1.4em;
      width: 100%;
    }

    p {
      font-size: 0.875em;
    }
  }

  .about-left {
    width: 100%;
    float: none;
    margin-bottom: 18px;
    padding: 0;
  }

  .contact {
    h3 {
      font-size: 1.4em;
    }

    p {
      margin-bottom: 1em;
      font-size: 0.875em;
      line-height: 1.8em;
    }
  }

  ul.form li {
    width: 98%;
    border: 3px solid #000;
  }

  input.text {
    width: 97%;
    padding: 0.5em;
  }

  textarea {
    padding: 0.5em;
    width: 96%;
    height: 125px;
  }

  input[type='submit'] {
    padding: 0.5em 0em;
    font-size: 1.1em;
    width: 94%;
  }

  .b-animate {
    font-size: 12px;
    width: 74%;
  }

  .contact {
    margin: 1.5em 0;
  }

  .copyrights {
    padding: 2em 0 1em;
  }

  div#portfoliolist {
    padding: 0 0px 0 5px;
  }

  .sub-button {
    border: 3px solid #000;
  }
}
